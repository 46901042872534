.bookings .card {
    border-radius: 8px;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    border: 0;
    background: #fff;
}
.booking-list-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.list-card{
    margin-right: 20px;
}
.bookings .list-title-1{
    font-size: 12px;
}
.list-title-2{
    font-size: 13px;
    color: #444;
}
.bookings button {
    font-size: 12px;
}
.bookings input, .bookings select {
    padding: 8px 20px;
    border-radius: 40px;
    font-size: 13px;
}
.booking-form-1 label{
    font-size: 13px;
    margin-bottom: 5px;
}
.ticket-container{
    background-image: url(../../assets/images/ticket-bg.png) !important;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 30px;
    width: 55%;
}

.ticket-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ticket-container .logo{
    width: 200px;
    padding-right: 15px;
}
.ticket-container .logoRight{
    width: 200px;
    border-left: 2px solid #6a3280;
    padding-left: 10px;
}

.ticket-inner-container{
    background: #fff;
    border: 5px solid #bad018;
    /* border: 5px solid #bad018;
    height: 85vh; */
}
.ticket-inner{
    padding: 40px 50px 15px;
}
.ticket-inner p{
    margin-bottom: 0px;
}
.ticket-overflow{
    background-color: #6a3280;
    color: #fff;
    padding: 2% 4%;
    text-align: justify;
    font-size: 20px;
}
#divToPrint{
    display: none;
}
.ticket-tnc{
    padding: 0px 50px 40px;
}

.ticket-tnc p {
    font-size: 11px !important;
    margin-bottom: 5px;
}
.ticket-tnc .title-main {
    font-size: 14px !important; 
}

@media print {
    footer {
        display: none;
    }
    header {
        display: none;
    }
    .print-btn{
        display: none;
    }
    .ticket-container{
        width: 100%;
        margin: 0;
        background-image: url(../../assets/images/ticket-bg.png) !important;
        background-repeat: no-repeat;
    }
    .hidePrint{
        display: none;
    }
    #divToPrint{
        display: block;
    }
    * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important;  /*Firefox*/
    }
    @page {
        margin-bottom: 15px;
        margin-top: 15px;
    }
}

@media (max-width: 786px){
    .booking-list-card {
        flex-wrap: wrap;
    }
}

@media print {
    .refund-pol {
        page-break-after: always;
    }
  }