.logo{
    width: 180px;
}
.logo-right{
    width: 110px;
}
header{
    border-bottom: 35px solid #e9702b;
}
.navbar-toggle-menu{
    display: contents;
}
.header-text{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    border-left: 2px solid #e9702b;
    padding-left: 10px;
}
.header-text p{
    margin-bottom: 0;
    font-size: 16px;
    text-transform: uppercase;
    color: #1c4f9b;
    font-family: 'verdana-bold';
}
.navbar-brand{
    padding: 0;
    margin: auto 0;
    margin-right: 10px;
}
.nav-top{
    max-width: 1200px;
    margin: auto;
    width: 100%;
    display: block;
    text-align: right;
    margin-bottom: -16px;
    margin-top: 5px;
    z-index: 99;
    position: relative;
    box-shadow: 1px 2px 4px #CCE;
}
.nav-top p{
    margin: 0;
    font-size: 13px;
}
.nav-top p b{
    color: #e9702b;
}
.custon-navbar{
    padding: 0px;
}
.navbar-toggler{
    width: 50px;
    height: 30px;
    padding: 0px;
}
.navbar-toggler-icon{
    width: 20px;
}
@media (min-width: 1300px) {
    .navbar{
        justify-content: space-between;
        margin: auto;
    }
}
.btn-cart{
    color: #000;
    text-decoration: none;
    margin: 0 8px;
    font-size: 13px;
    font-weight: 600;
}
.btn-cart i{
    font-size: 25px;
    margin-right: 5px;
    margin-left: 5px;
}

.dropdown-toggle.nav-link{
    color: #000;
    margin: 0 8px;
    font-size: 13px;
    font-weight: 600;
}
.btn-cart:hover , .dropdown-toggle.nav-link:hover{
    color: #e9702b;
}
.dropdown-menu .dropdown-item{
    font-size: 13px;
}
.sponsor-logo{
    margin: auto 0;
    margin-left: 20px;
}
.sponsor-logo p{
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 600;
}
.sponsor-logo img{
    width: 120px;
}
@media (min-width: 992px){
.navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: space-between;
}  
}
@media (max-width: 786px){
    .sponsor-logo p{
        margin-bottom: 0px;
        font-size: 8px;
        font-weight: 600;
        line-height: 6px;
    }
    .sponsor-logo img {
        width: 60px;
    }
  
    .navbar{
        padding: 10px;
    }
    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none;
    }
    .header-text p {
        margin-bottom: 0;
        font-size: 8px;
    }
    .logo {
        width: 120px;
    }
    .navbar-toggle-menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 15px;
        background: #fff;
        padding: 15px;
        z-index: 9;
        box-shadow: 1px 1px 5px 0px #ccf;
    }
    .nav-top {
        margin-bottom: -2px;
        max-width: 100%;
        text-align: center;
        margin-top: 4px;
    }
    .nav-top p{
        font-size: 9px;
    }
    .btn-primary, .btn-cart, .dropdown-toggle.nav-link {
        font-size: 12px;
        font-weight: 700;
        margin: 7px 5px !important;
    }
    .logo-right {
        width: 80px;
    }
}