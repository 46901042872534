
footer{
    background: #000;
    color: #fff;
    padding: 25px 0px 0px;
}
.footer-ul{
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.footer-ul a{
    text-transform: uppercase;
    font-size: 24px;
    color: #e9702b;
    font-family: 'futuraptheavy';
    text-decoration: none;
    line-height: 36px;
}

.footer-logo{
    width: 140px;
}
footer .bg-pink p{
    font-size: 14px;
    font-family: 'futuraptheavy';
}
.social-links{
    display: flex;
    list-style: none;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 7px;
    justify-content: center;
}

footer .social-links a{
    background: #e9702b;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #343434;
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 50px;
    margin: 0px 10px;
    font-size: 26px;
}
.footer-col-4{
    justify-content: space-around;
}
.footer-col-4 h5{
    font-size: 15px;
    font-family: 'verdana-bold';
    color: #e9702b;
}
.footer-col-4 p{
    font-size: 13.5px;
    line-height: 18px;
}
.footer-col-4 h5{
    cursor: pointer;
}
.footer-col-bottom{
    background-color: #e9702b;
    text-align: center;
}

.footer-col-bottom h5{
    font-size: 13px;
    padding: 7px;
    margin-bottom: 0;
    color: #000;
    font-family: 'verdana-bold';
}

@media (max-width: 786px) {
    
    .social-links {
        display: flex;
        list-style: none;
        padding: 0;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
        justify-content: flex-start;
    }
    footer .social-links a {
        background: #e9702b;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #343434;
        justify-content: center;
        text-align: center;
        align-items: center;
        line-height: 35px;
        margin: 0px 6px;
        font-size: 18px;
    }
    footer {
        padding: 0px;
    }
    .footer-logo {
        width: 100px;
    }
    .footer-col-4 {
        justify-content: space-around;
        display: flex;
        flex-direction: column;
    }
    .footer-col-4 p {
        font-size: 11.5px;
        line-height: 18px;
    }
}