
@font-face {
    font-family: 'verdana';
    src: url(../fonts/verdana.ttf);
}

@font-face {
    font-family: 'verdana-bold-italic';
    src: url(../fonts/verdana-bold-italic.ttf);
}

@font-face {
    font-family: 'verdana-bold';
    src: url(../fonts/verdana-bold.ttf);
}
@media print {
    * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important;  /*Firefox*/
    }
    @page {
        margin: 0;
    }
}
body{
    position: relative;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Verdana';
}
@media (min-width: 1300px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1200px;
    }
}

.main-wrapper{
    min-height: 85vh;
}
textarea.form-control {
    border-radius: 30px;
}
.btn-primary{
    background-color: #e9702b;
    border-radius: 20px;
    font-size: 14px;
    padding: 6px 20px;
    text-transform: uppercase;
    border-color: #e9702b;
}
.btn-primary:hover{
    background-color: #e9702b;
    border-radius: 20px;
    font-size: 14px;
    padding: 6px 20px;
    text-transform: uppercase;
    border-color: #e9702b;
}
.btn-success{
    background-color: #198754 !important;
}
.btn-danger{
    background-color: #dc3545 !important;
}
.carousel{
    height: 350px;
}
.carousel-item img{
    height: 350px;
    background-size:contain;
    /* object-fit: cover; */
}

.title-1{
    font-size: 21px;
    text-transform: uppercase;
    border-left: 10px solid #e9702b;
    padding: 7px 10px;
    margin-bottom: 30px;
    font-family: 'verdana-bold';
}

.title-main{
    font-size: 23px;
    text-transform: uppercase;
    font-family: 'verdana-bold';
}

/* event css */
.evt-details-left{
    position: relative;
    border-radius: 30px;
}
.evt-details-left span{
    background: #000;
    color: #BFD730;
    font-size: 9px;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: 'verdana-bold';
    position: absolute;
    right: 15px;
    top: 10px;
}
.evt-details-left img{
    width: 100%;
    border-radius: 30px;
}

.evt-details-right h1{
    font-family: 'verdana-bold';
    font-size: 18px;
    /* padding-top: 10px; */
}

.evt-details-right p{
    font-size: 14px;
    font-weight: 300;
}
.evt-details-right p{
    font-family: 'verdana-bold';
}
.evt-details-right p img{
    width: 16px;
    margin-right: 10px;
}

.evt-details-right h4 img{
    width: 16px;
    margin-right: 10px;
}
.evt-details-right h4 i{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 15px;
}
.evt-details-right h4{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
}

.evt-details-right .btn-primary{
    padding: 10px 20px;
}

/* partners section */
.partners-section{
    display: flex;
    flex-direction: row;
}
.partners-logo{
    padding: 0 10px;
}
.partners-section .partners-logo{
    flex-basis: 20%;
    width: 20%;
    padding: 15px;
    border-radius: 20px;
    margin: 20px 0;
}
.partners-logo img{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 25px;
}

.refund-section li p{
    font-size: 15px;
    margin-bottom: 2px;
}
/* about section */
.about-section p{
    font-size: 15px;
    text-align: justify;
}
.about-section .text-bold{
    font-family: 'verdana-bold';
    font-weight: 600;
    padding: 20px 0 15px;
    font-size: 20px;
}

.about-section .card{
    padding: 20px;
    max-height: 200px;
    min-height: 200px;
    box-shadow: 0 1px 2px 0 #0000001a;
    border: 2px solid #e9702b;
}
.about-section-left h4{
    font-size: 20px;
    font-family: 'verdana-bold';
    color: #6a3280;
}
.about-section p b{
    font-family: 'verdana-bold';
}
.title-about-main{
    font-family: 'verdana-bold';
    font-size: 22px;
}
.about-section img{
    /* padding: 20px 8%; */
}
.about-section-2 h6{
    font-size: 20px;
    font-family: 'verdana-bold';
    text-transform: uppercase;
}
.about-section-2 p{
    font-size: 15px;
}
/* about section */
.category-section{
    display: flex;
    flex-direction: row;
    /* padding: 0 8%; */
}

.category-section .category-logo{
    flex-basis: 33%;
    width: 33%;
    padding: 10px 30px;
    border-radius: 20px;
    border-radius: 20px;
    margin: 0 20px;
    background-color: #BFD730;
}
.category-logo h1{
    font-size: 34px;
    text-align: center;
    margin: 20px 0px 10px;
    font-family: 'verdana-bold';
}
.category-logo p{
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-align: center;
}

.category-section .text-bold{
    text-align: center;
    font-family: 'verdana-bold';
}

/* refund section */

.refund-section p{
    font-size: 16px;
}

/* Register page */
/* info section */
.info-section{
    /* padding: 20px 8%; */
}
.info-section-inner{
    display: flex;
    justify-content: space-around;
}
.info-section p{
    font-family: 'verdana-bold';
    font-size: 14px;
}
.info-section-inner a{
    color: inherit;
    text-decoration: none;
}
.info-section-inner a:hover{
    color: #6a3280;
    text-decoration: none;
}
.info-section p img{
    width: 27px;
    margin-right: 10px;
}
.info-section p .img1{
    width: 40px;
    margin-right: 10px;
}
.info-section-img-3{
    width: 22px !important;
}
/* Register Category */
.category-section-reg .category-logo{
    padding: 0;
    border: 1px solid #707070;
    background-color: #fff;
    
}

.category-section-reg  .category-logo h1 {
    font-size: 20px;
    font-family: 'verdana-bold';
    color: #000;
    text-align: center;
    margin: 0px;
    border-radius: 20px 20px 0px 0px;
    background: #eee;
    padding: 20px;
}

.category-section-reg .category-p{
    padding: 10px 30px;
    font-size: 20px;
}
.category-section-reg .category-logo p {
    font-size: 20px;
}
.category-section-reg a{
    margin-top: 10px;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
/* sponser */
.sponser-section .sponser-logo{
    width: 250px;
    border: 1px solid #444;
    /* border-radius: 20px */
}
.sponser-section .sponser-banner{
    width: 100%;
    border-radius: 30px;
    margin: 30px 0;
}
/* category-input-box */
.category-input-box{
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
    background: #e9702b;
    border-radius: 0px 0px 20px 20px;
}

.category-input-box button{
    border: 0;
    background: #fff;
    width: 30px;
    height: 30px;
    line-height: 25px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
}

.count{
    background: #fff;
    width: 40px;
    line-height: 30px;
    height: 30px;
    border-radius: 20px 0px 0px 20px;
    margin-right: -10px;
    margin-left: 3px;
    color: #000;
}
.category-section-reg.category-input  .category-logo {
    border: 2px solid #444;
}
.category-section-reg.category-input  .category-logo h1 {
    background: #e9702b;
    padding: 20px;
    border-radius: 18px 18px 0px 0px;
    color: #000;
}

.category-section-reg.category-input  .category-input-box{
    border-radius: 0px 0px 18px 18px ;
}

/* participant-details */
.participant-details{
    padding: 20px 0;
}

.participant-details input, .participant-details select{
    padding: 15px 20px;
    border-radius: 40px;
}
.participant-details button{
    font-size: 16px;
    border-radius: 30px;
    float: right;
    border-color: #ccc;
}

.participant-details button.btn-confirm{
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 30px;
    float: right;
    margin-top: 30px;
}

.participant-details .country-code{
    border-radius: 30px 0px 0px 30px;
    max-width: 200px;
    border-right: 0;
    
}

.participant-details .form-phone{
    border-left: 0;
}

/* summary-box */
.summary-box{
    /* padding: 20px 7%; */
}

.summary-box-wrp{
    border: 1px solid #ccc;
    border-radius: 20px;
}
.summary-box-wrp h2{
    background: #c2d931;
    padding: 18px 15px;
    color: #000;
    font-family: 'verdana-bold';
    font-size: 20px;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    text-transform: uppercase;
}

.summary-box-wrp .summary-inner{
    padding: 20px;
}

.summary-inner p{
    margin-bottom: 10px;
    color: #444;
}
.btn-apply{
    background-color: #e9702b;
    border-color: #e9702b;
    font-family: 'verdana-bold';
    color: #000;
}
.summary-inner input{
    border-radius: 20px 0px 0px 20px;
}

.summary-inner .border-dashed{
    border: 1px dashed #ccc;
}

.summary-inner button.btn-checkout{
    border-radius: 30px;
    background-color: #343434;
    border-color: #343434;
    width: 100%;
    padding: 14px;
}

.btn-checkout i{
    font-size: 25px;
    color: #BFD730;
    margin-right: 5px;
}
.react-multiple-carousel__arrow::before {
    font-size: 30px !important;
    color: #fff !important;
}

.react-multiple-carousel__arrow{
    background-color: transparent !important;
}

.react-multiple-carousel__arrow--left {
    left: 10px !important;
}

.react-multiple-carousel__arrow--right {
    right: 10px !important;
}

.prize-money-conatiner ul{
    list-style: none;
    margin-bottom: 0;
}
.prize-money-conatiner ul li{
    list-style: disc;
    font-size: 13px;
}
.prize-money-conatiner .image-row{
    margin-bottom: -20px;
}

.prize-money-conatiner .image-row img{
    width: 40%;
}
.fa-trophy{
    color: gold;
    padding: 0px 6px;
    font-size: 16px !important;
}
.prize-money-conatiner .head-1{
    font-size: 16px;
    font-family: 'verdana-bold';
    margin-bottom: 10px;
}

.prize-money-conatiner .heading-1{
    font-size: 18px;
    font-family: 'verdana-bold';
}

.table-prize-men tr td{
    padding: 8px 15px;
    text-align: center;
    border: 1px solid #6a3280;
}
.table-prize-men p{
    font-size: 12px;
    font-family: 'verdana-bold';
    margin-bottom: 0px;
}
.table-prize-men .td-bg-color{
    background: aliceblue;
    color: #6a3280;
    width: 30%;
} 

.table-prize-men .td-bg-color p{
    font-size: 12px;
    text-align: center;
}  


.table-prize-women  tr td{
    padding: 8px 15px;
    border: 1px solid #e9702b;
    text-align: center;
}
.table-prize-women p{
    font-size: 12px;
    font-family: 'verdana-bold';
    margin-bottom: 0px;
}

.table-prize-women .td-bg-color{
    background: #e9702b0f;
    color: #8BC34A;
    width: 30%;
}
.table-prize-women .td-bg-color p{
    font-size: 12px;
    text-align: center;
}

.table-top img{
    width: 40%;
}
.table-top{
    border: none;
}
.table-top td{
    border: none !important;
}
.table-head-1{
    margin-bottom: -3% !important;
}

.alert-box i{
    font-size: 18px;
}
.alert-box .alert{
    padding: 15px 15px;
    color: orangered;
    font-size: 14px;
}
.alert-box  .alert-heading{
    color: orangered;
    font-size: 16px;
    font-weight: 600;
}

.toggle-show{
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    color: #E91E63;
}

.show-para{
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}

.register-column h5{
    font-size: 17px;
}
.register-column .refund-section li p {
    font-size: 14px;
    margin-bottom: 2px;
}
 
.register-column ul {
    padding-left: 1.5rem;
}

.inner-list li{
    list-style: disc;
    padding-right: 10px;
}

.border-right{
    border-right: 1px solid #ccc;
}

.border-left{
    border-left: 1px solid #ccc;
    padding-left: 30px;
}
.result-section {
    box-shadow: 0 0 10px rgba(199, 181, 181, 0.25);
    border-radius: 15px;
    margin: 10px auto 10px;
    width: 80%;
}
.result-section table th{
    padding: 15px 10px;
    background: #e9702b24;
    border-bottom: 2px solid #e9702b;
    font-size: 12px;
    font-family: 'verdana-bold';
    text-align: center;
}
.result-section .btn-primary {
    background-color: #e9702b;
    border-radius: 20px;
    font-size: 14px;
    border-color: #e9702b;
    padding: 6px 20px;
    text-transform: uppercase;
}
.result-section img {
    width: 35px;
}
.result-section table td{
    padding: 13px 10px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}
.result-section table tr{
    box-shadow: 0px -1px 5px -3px #ccc;
}
.result-section .form-select{
    padding: 10px;
    font-size: 14px;
}


.map-img{
    width: 50%;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
@media (max-width: 786px) {
    .register-column h5 {
        font-size: 14px;
    }
    .border-left{
        border-left: 0;
        padding: 0px 15px;
    }
    .carousel-indicators {
        bottom: -20px !important;
    }
    .px-xs-0{
        padding-left: 0px;
        padding-right: 0px;
    }

    .navbar {
        padding: 0 15px;
    }
    .carousel {
        height: auto;
    }
    .carousel-item img {
        height: auto;
        object-fit: cover;
    }
    .title-main {
        font-size: 16px;
    }
    .about-section-left h4 {
        font-size: 17px;
    }
    .evt-details-right{
        margin-top: 15px;
    }
    .evt-row{
        padding: 0 20px;
    }
    .category-section {
        display: block;
    }
    .category-section .category-logo{
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
    }
    .partners-section {
        display: flex;
        /* flex-direction: revert; */
        flex-wrap: wrap;
    }
   
    .partners-section .partners-logo {
        flex-basis: 50%;
        width: 50%;
        padding: 7px;
        border-radius: 20px;
        margin: 0px;
    }
    .px-xs-1{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .about-section p {
        font-size: 15px;
        padding: 10px 5%;
        text-align: center;
    }
    .refund-section p {
        font-size: 13px !important;
    }
    .btn-checkout{
        margin-bottom: 15px;
    }
    .participant-details div{
        margin-top: 10px;
    }

    .info-section p .img1 {
        width: 20px;
        margin-right: 5px;
    }
    .info-section p img {
        width: 15px;
        margin-right: 5px;
    }
    .info-section-img-3 {
        width: 15px !important;
    }
    .btn-primary , .btn-cart{
        font-size: 10px;
        font-weight: 700;
        margin-left: 2px !important;
    }
    
    .btn-cart i {
        font-size: 18px;
    }
    .sponser-section .sponser-banner{
        border-radius: 15px;
    }
    .sponser-section p{
        font-size: 14px;
        margin-top: 15px;
    }
    .sponser-section .sponser-logo {
        width: 150px;
        border: 1px solid #444;
        border-radius: 10px;
    }
    .about-section .card{
        padding: 20px;
        max-height: 200px;
        min-height: 200px;
        margin-bottom: 15px;
    }
    .about-section .card p {
        font-size: 14px;
        padding: 0px 5%;
    }
    .info-section p{
        font-family: 'verdana-bold';
        font-size: 12px;
    }
    .table-prize-men p,  .table-prize-women p {
        font-size: 10px !important;
    }
    .table-prize-men tr td,  .table-prize-women tr td {
        padding: 4px 11px;
    }
    .table-top img{
        width: 35px;
    }
    .alert-box .alert {
        font-size: 12px;
    }

    .map-img{
        width: 100%;
    }
    .about-section .text-bold{
        font-size: 17px;
    }
}